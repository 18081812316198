
// This file is autogenerated, do not edit!
// Look into the 'generate-widgets-and-types.ts' in the root folder

// tslint:disable
import React from 'react';
import { render } from 'react-dom';
import { initAnalytics } from 'common/primitives/analytics';
import { useConfig, boostrapBeforeWidgetsRender } from 'common/hooks/useBoostrap';
import * as Sentry from '@sentry/browser';

const sentryDns = useConfig('sentryDns', '')
const env = useConfig('env', 'development');
const currentRelease = useConfig('currentRelease', 'development');
if (sentryDns) {
    Sentry.init({
        environment: env,
        dsn: sentryDns,
        release: currentRelease,
        integrations: []
    });
}

const widgets = window.widgets || [];
const featureFlags = new Set(window.config.features ?? []);
let hasInit = false;
window.vitraInit = () => {
    if (hasInit) {
        return;
    }
    hasInit = true;

    boostrapBeforeWidgetsRender();

    const io = new IntersectionObserver(entries => {
        const intersecting = entries.filter(e => e.isIntersecting);
        intersecting.forEach(element => {
            const elementId = element.target.getAttribute('id');
            const elementType = element.target.getAttribute('data-type');
            const elementData = widgets.find(w => w.id === elementId) || {};

            switch(elementType) {
                
            case 'vitra--articles-teaser':
                
                import(/* webpackChunkName: "vitra--articles-teaser" */ './components/articles-teaser').then((Widget) => {
                    render(<Widget.default {...elementData} />, element.target);
                });
                break;
            
            case 'vitra--parallax-teaser':
                
                import(/* webpackChunkName: "vitra--parallax-teaser" */ './components/parallax-teaser').then((Widget) => {
                    render(<Widget.default {...elementData} />, element.target);
                });
                break;
            
            case 'vitra--info-banner':
                
                import(/* webpackChunkName: "vitra--info-banner" */ './components/info-banner').then((Widget) => {
                    render(<Widget.default {...elementData} />, element.target);
                });
                break;
            
            case 'vitra--teaser':
                
                import(/* webpackChunkName: "vitra--teaser" */ './components/teaser').then((Widget) => {
                    render(<Widget.default {...elementData} />, element.target);
                });
                break;
            
            case 'vitra--media':
                
                import(/* webpackChunkName: "vitra--media" */ './components/media').then((Widget) => {
                    render(<Widget.default {...elementData} />, element.target);
                });
                break;
            
            case 'vitra--breadcrumbs':
                
                import(/* webpackChunkName: "vitra--breadcrumbs" */ './components/breadcrumbs').then((Widget) => {
                    render(<Widget.default {...elementData} />, element.target);
                });
                break;
            
            case 'vitra--find-vitra':
                
                import(/* webpackChunkName: "vitra--find-vitra" */ './components/find-vitra').then((Widget) => {
                    render(<Widget.default {...elementData} />, element.target);
                });
                break;
            
            case 'vitra--listing':
                
                import(/* webpackChunkName: "vitra--listing" */ './components/listing').then((Widget) => {
                    render(<Widget.default {...elementData} />, element.target);
                });
                break;
            
            case 'vitra--hero':
                
                import(/* webpackChunkName: "vitra--hero" */ './components/hero').then((Widget) => {
                    render(<Widget.default {...elementData} />, element.target);
                });
                break;
            
            case 'vitra--product-designer-or-family':
                
                import(/* webpackChunkName: "vitra--product-designer-or-family" */ './components/product-designer-or-family').then((Widget) => {
                    render(<Widget.default {...elementData} />, element.target);
                });
                break;
            
            case 'vitra--tabs':
                
                import(/* webpackChunkName: "vitra--tabs" */ './components/tabs').then((Widget) => {
                    render(<Widget.default {...elementData} />, element.target);
                });
                break;
            
            case 'vitra--video':
                
                import(/* webpackChunkName: "vitra--video" */ './components/video').then((Widget) => {
                    render(<Widget.default {...elementData} />, element.target);
                });
                break;
            
            case 'vitra--client-only':
                
                import(/* webpackChunkName: "vitra--client-only" */ './components/client-only').then((Widget) => {
                    render(<Widget.default {...elementData} />, element.target);
                });
                break;
            
            case 'vitra--picture-poi':
                
                import(/* webpackChunkName: "vitra--picture-poi" */ './components/picture-poi').then((Widget) => {
                    render(<Widget.default {...elementData} />, element.target);
                });
                break;
            
            case 'vitra--gallery':
                
                import(/* webpackChunkName: "vitra--gallery" */ './components/gallery').then((Widget) => {
                    render(<Widget.default {...elementData} />, element.target);
                });
                break;
            
            case 'vitra--book-consultations':
                
                import(/* webpackChunkName: "vitra--book-consultations" */ './components/book-consultations').then((Widget) => {
                    render(<Widget.default {...elementData} />, element.target);
                });
                break;
            
                default:
                    // console.warn(elementType +' not found');
                    break;
            }

            io.unobserve(element.target);
        });
    });

    // Instant render Widgets
    widgets.forEach(widget => {
        const element = document.getElementById(widget.id);
        if (!element) {
            return;
        }
        const elementType = element.getAttribute('data-type');
        const elementData = widget || {};
        switch(elementType) {
            
            case 'vitra--main-navigation-v2':
                
                import(/* webpackChunkName: "vitra--main-navigation-v2" */ './components/main-navigation-v2').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--main-footer':
                
                import(/* webpackChunkName: "vitra--main-footer" */ './components/main-footer').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--configure':
                if (featureFlags?.has('FrontendV2Enabled')) {break;}

                import(/* webpackChunkName: "vitra--configure" */ './components/configure-v3').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--cookie-consent':
                
                import(/* webpackChunkName: "vitra--cookie-consent" */ './components/cookie-consent').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--server-error':
                
                import(/* webpackChunkName: "vitra--server-error" */ './components/server-error').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--checkout-v2':
                
                import(/* webpackChunkName: "vitra--checkout-v2" */ './components/checkout-v2').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--checkout-success-v2':
                
                import(/* webpackChunkName: "vitra--checkout-success-v2" */ './components/checkout-success-v2').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--cart-v2':
                
                import(/* webpackChunkName: "vitra--cart-v2" */ './components/cart-v2').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--account-v2':
                
                import(/* webpackChunkName: "vitra--account-v2" */ './components/account-v2/account').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--account-register-v2':
                
                import(/* webpackChunkName: "vitra--account-register-v2" */ './components/account-v2/account-register').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--history-animation':
                
                import(/* webpackChunkName: "vitra--history-animation" */ './components/history-animation').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--external':
                
                import(/* webpackChunkName: "vitra--external" */ './components/external').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--parcellab':
                
                import(/* webpackChunkName: "vitra--parcellab" */ './components/parcellab').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--paqato':
                
                import(/* webpackChunkName: "vitra--paqato" */ './components/paqato').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--newsletter':
                
                import(/* webpackChunkName: "vitra--newsletter" */ './components/newsletter/newsletter-widget').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--popup-newsletter':
                
                import(/* webpackChunkName: "vitra--popup-newsletter" */ './components/newsletter/newsletter-popup').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--text':
                
                import(/* webpackChunkName: "vitra--text" */ './components/text').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--search':
                
                import(/* webpackChunkName: "vitra--search" */ './components/search').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--checkout-error-v2':
                
                import(/* webpackChunkName: "vitra--checkout-error-v2" */ './components/checkout-error-v2').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--campus-map':
                
                import(/* webpackChunkName: "vitra--campus-map" */ './components/campus-map').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--materials-detail':
                
                import(/* webpackChunkName: "vitra--materials-detail" */ './components/materials-detail').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--contact-wizard':
                
                import(/* webpackChunkName: "vitra--contact-wizard" */ './components/contact-wizard').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--popup':
                
                import(/* webpackChunkName: "vitra--popup" */ './components/popup').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--wishlist-v2':
                
                import(/* webpackChunkName: "vitra--wishlist-v2" */ './components/wishlist-v2').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            case 'vitra--client-only-iframe':
                
                import(/* webpackChunkName: "vitra--client-only-iframe" */ './components/client-only-iframe').then((Widget) => {
                    render(<Widget.default {...elementData} />, element);
                });
                break;
            
            default:
                // console.warn(elementType +' not found');
                break;
        }
    });

    // Lazy Widgets
    widgets.forEach(widget => {
        const element = document.getElementById(widget.id);
        if (element) {
            io.observe(element);
        }
    });

    initAnalytics();
};
// auto init when it's not IE
window.vitraInit();
